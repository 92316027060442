import React from 'react';

const JenkinsIntegration = () => {
  return (
    <div className="jenkins-integration-guide">
      <h2 className="darkText">Jenkins Integration Guide for SAST and SCA Scans</h2>
      <hr/>

      <section>
        <h3 className="darkText">Step 1: Create Jenkinsfile</h3>
        <p>In your project repository, create a file named <code>Jenkinsfile</code> in the root directory with the following content:</p>

        <pre className="code-block">
          <code>
{`pipeline {
    agent any

    environment {
        CLIENT_ID = 'your_intrucept_client_secret'
        CLIENT_SECRET = 'your_intrucept_client_id'
        APPLICATION_ID = 'your_intrucept_application_id'
        SCA_API_URL = 'https://appsecops-api.intruceptlabs.com/api/v1/integrations/sca-scans'
        SAST_API_URL = 'https://appsecops-api.intruceptlabs.com/api/v1/integrations/sast-scans'
    }

    stages {
        stage('Clean Up Old Files') {
            steps {
                script {
                    sh 'rm -rf venv'
                    sh 'rm -rf project.zip'
                    sh 'rm -rf *.json'
                    sh 'rm -rf *.csv'
                    sh 'rm -rf *.sh'
                }
            }
        }

        stage('Checkout Code') {
            steps {
                checkout scm
            }
        }

        stage('Create ZIP Files') {
            steps {
                script {
                    sh 'rm -rf project_folder'
                    sh 'mkdir project_folder'
                    sh 'find . -maxdepth 1 -not -name "." -not -name ".." -not -name ".git" -not -name "venv" -not -name "project_folder" -exec mv {} project_folder/ \\;'
                    sh 'zip -r project.zip project_folder'
                }
            }
        }

        stage('Perform SCA Scan') {
            steps {
                script {
                    def response = sh(script: """
                        #!/bin/bash
                        curl -v -X POST \
                        -H "Client-ID: \${CLIENT_ID}" \
                        -H "Client-Secret: \${CLIENT_SECRET}" \
                        -F "projectZipFile=@project.zip" \
                        -F "applicationId=\${APPLICATION_ID}" \
                        -F "scanName=Movieflix-JavaScript-SCA Scan" \
                        -F "language=javascript" \
                        "\${SCA_API_URL}"
                    """, returnStdout: true).trim()

                    def jsonResponse = readJSON(text: response)
                    def canProceedSCA = jsonResponse.canProceed
                    def vulnsTable = jsonResponse.vulnsTable

                    def cleanVulnsTable = vulnsTable.replaceAll(/\x1B\[[;0-9]*m/, '')

                    echo "Vulnerabilities found during SCA:"
                    echo "\${cleanVulnsTable}"

                    env.CAN_PROCEED_SCA = canProceedSCA.toString()
                }
            }
        }

        stage('Perform SAST Scan') {
            steps {
                script {
                    def response = sh(script: """
                        #!/bin/bash
                        curl -v -X POST \
                        -H "Client-ID: \${CLIENT_ID}" \
                        -H "Client-Secret: \${CLIENT_SECRET}" \
                        -F "projectZipFile=@project.zip" \
                        -F "applicationId=\${APPLICATION_ID}" \
                        -F "scanName=Movieflix-JavaScript-SAST Scan" \
                        -F "language=javascirpt" \
                        "\${SAST_API_URL}"
                    """, returnStdout: true).trim()

                    def jsonResponse = readJSON(text: response)
                    def canProceedSAST = jsonResponse.canProceed
                    def vulnsTable = jsonResponse.vulnsTable

                    def cleanVulnsTable = vulnsTable.replaceAll(/\x1B\[[;0-9]*m/, '')

                    echo "Vulnerabilities found during SAST:"
                    echo "\${cleanVulnsTable}"

                    env.CAN_PROCEED_SAST = canProceedSAST.toString()
                }
            }
        }

        // Additional stages (e.g., deploy) can be added here
    }
}
`}
          </code>
        </pre>
      </section>

      <section>
        <h3 className="darkText">Step 2: Update Configuration Details</h3>
        <p>In the Jenkinsfile:</p>
        <ol>
          <li>Replace the placeholder values for <code>CLIENT_ID</code>, <code>CLIENT_SECRET</code>, and <code>APPLICATION_ID</code> with your actual Intrucept credentials.</li>
          <li>Adjust the <code>language</code> parameter in the API calls if your project uses a different primary language.</li>
          <li>Modify the Python setup and dependency installation steps if your project uses a different runtime or package manager.</li>
        </ol>
      </section>

      <section>
        <h3 className="darkText">Step 3: Set Up Jenkins Pipeline</h3>
        <ol>
          <li>Log in to your Jenkins dashboard.</li>
          <li>Click on <strong>New Item</strong> at the top left of the Jenkins dashboard.</li>
          <li>Enter a name for your new pipeline job (e.g., "My-Project-SAST-SCA").</li>
          <li>Select <strong>Pipeline</strong> as the job type and click <strong>OK</strong>.</li>
          <li>In the job configuration page, scroll down to the <strong>Pipeline</strong> section.</li>
          <li>For <strong>Definition</strong>, select "Pipeline script from SCM" from the dropdown.</li>
          <li>For <strong>SCM</strong>, choose your source control system (e.g., Git).</li>
          <li>In the <strong>Repository URL</strong> field, enter the URL of your Git repository.</li>
          <li>If your repository requires authentication, add your credentials by clicking the <strong>Add</strong> button next to <strong>Credentials</strong> and filling in the required information.</li>
          <li>In the <strong>Branch Specifier</strong> field, specify the branch you want to build (e.g., <code>*/main</code> for the main branch).</li>
          <li>Ensure the <strong>Script Path</strong> is set to <code>Jenkinsfile</code>.</li>
          <li>Click <strong>Save</strong> at the bottom of the page.</li>
        </ol>
      </section>

      <section>
        <h3 className="darkText">Step 4: Run the Build</h3>
        <ol>
          <li>After saving the job configuration, you'll be taken to the job's main page.</li>
          <li>Click on <strong>Build Now</strong> in the left sidebar to start the build process.</li>
          <li>Jenkins will fetch your repository, read the Jenkinsfile, and execute the defined pipeline stages.</li>
          <li>You can view the progress of the build by clicking on the build number in the <strong>Build History</strong> section and then selecting <strong>Console Output</strong>.</li>
        </ol>
      </section>

      <section>
        <h3 className="darkText">Step 5: View Results</h3>
        <ol>
          <li>Once the build is complete, you can view the results in the Console Output.</li>
          <li>The SAST and SCA scan results, including any vulnerabilities found, will be displayed in the console output.</li>
          <li>If either scan fails (i.e., <code>CAN_PROCEED</code> is not 'true'), the build will be marked as failed.</li>
        </ol>
      </section>

      <section>
        <h3 className="darkText">Explanation of the Jenkins Pipeline</h3>
        <p>This Jenkins pipeline does the following:</p>
        <ol>
          <li>Defines environment variables with Intrucept credentials and API URLs.</li>
          <li>Cleans up old files and prepares the workspace.</li>
          <li>Checks out the code from the repository.</li>
          <li>Creates a ZIP file of the project for scanning.</li>
          <li>Performs an SCA scan using the Intrucept API.</li>
          <li>If SCA passes, performs a SAST scan using the Intrucept API.</li>
          <li>Sets up a Python environment and installs dependencies.</li>
        </ol>
      </section>

      <section>
        <h3 className="darkText">Important Notes</h3>
        <p><strong>Security Note:</strong> The current setup uses hardcoded credentials in the Jenkinsfile. For improved security, consider using Jenkins' built-in credentials management system and injecting these values securely at runtime.</p>
        <p><strong>Prerequisites:</strong> Ensure that you have the necessary plugins installed in Jenkins (e.g., Pipeline, Git) and that your Jenkins instance has the required permissions to execute shell commands and make HTTP requests.</p>
      </section>
    </div>
  );
};

export default JenkinsIntegration;