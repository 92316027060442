import React, { useState, useEffect, useRef } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CRow,
  CFormLabel,
  CFormSelect,
  CTextarea
} from '@coreui/react'
import { useParams, useNavigate } from 'react-router-dom'
import { IoMdArrowRoundBack } from "react-icons/io";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { validatePersonName, validateEmail, validatePhoneNumber, validatePassword } from '../../../../helpers/validation';
import { isValidPhoneNumber } from 'react-phone-number-input'

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { isPossiblePhoneNumber } from 'react-phone-number-input'


const AddUser = () => {

  const navigate = useNavigate()

  const [firstName, setFirstName] = useState("")
  const [role, setRole] = useState("Administrator")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [collectionUrl, setCollectionUrl] = useState("")
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false)
  const [applicationId, setApplicationId] = useState('')
  const [applications, setApplications] = useState([])
  const [language, setLanguage] = useState('')

  const [users, setUsers] = useState([])
  const [businessUnits, setBusinessUnits] = useState([])
  const [assetGroups, setAssetGroups] = useState([])

  const [userType, setUserType] = useState('user')
  const [businessUnit, setBusinessUnit] = useState('')
  const [location, setLocation] = useState('')
  const [theStatus, setTheStatus] = useState('Active')

  const [validationFailed, setValidationFailed] = useState(false);
  const [errorText, setErrorText] = useState('');

  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const toaster = useRef();

  const statuses = ['Active', 'Suspended']

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phoneNumber: '',
  });

  useEffect(() => {
    fetchBusinessUnits();
  }, []); 

  const fetchBusinessUnits = async () => {
    setLoading(true);
    try {
        const token = localStorage.getItem('ASIToken');
        const response = await axios.get('/api/v1/organizations/business-units/all/all', {
            headers: { Authorization: `Bearer ${token}` },
        });
        
        setBusinessUnits(response.data.businessUnits);
        if (response.data.businessUnits.length > 0) {
            setBusinessUnit(response.data.businessUnits[0]._id);
        } else {
            setBusinessUnit('');
        }
    } catch (error) {
        console.error('Error fetching business units:', error);
    } finally {
        setLoading(false);
    }
};

  const validateForm = () => {
    let isValid = true;
    let newErrors = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      phoneNumber: '',
    };

    if (!validatePersonName(firstName)) {
      newErrors.firstName = 'Invalid first name. Only letters and spaces are allowed (max 100 characters).';
      isValid = false;
    }

    if (!validatePersonName(lastName)) {
      newErrors.lastName = 'Invalid last name. Only letters and spaces are allowed (max 100 characters).';
      isValid = false;
    }

    if (!validateEmail(email)) {
      newErrors.email = 'Invalid email address.';
      isValid = false;
    }

    if (!validatePassword(password)) {
      newErrors.password = 'Password must be 12-128 characters long and contain at least one lowercase letter, one uppercase letter, one digit, and one special character.';
      isValid = false;
    }

    console.log('phoneNumber:',phoneNumber);

    if (!validatePhoneNumber(phoneNumber)) {
      newErrors.phoneNumber = 'Invalid phone number.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const addUser = () => {
    if (validateForm()) {
      setLoading(true)

      const bearerToken = localStorage.getItem('ASIToken');

      const requestData = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        role: role,
        phoneNumber: phoneNumber,
        businessUnit: businessUnit,
        userType: userType,
        location: location,  
        status: theStatus,
        password: password
      };

      fetch(global.backendUrl + '/api/v1/organizations/users', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${bearerToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      })
        .then(response => response.json())
        .then(data => {

console.log('data:',data)

          if (data.hasOwnProperty('error')) {
            setValidationFailed(true);
            setErrorText(data.error);
            setLoading(false);
          }
          else if (data.hasOwnProperty('err')) {
            setLoading(false);
            setValidationFailed(true);
            setErrorText("Something went wrong. Please try again");
          } else {
            setSubmissionSuccess(true);
            setLoading(false);
            toast('User added', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            navigate('/users')
          }
        })
        .catch(error => {
          console.error(error);
        });      
    }
  }

  const goBack = (e) => {
    e.preventDefault();
    navigate('/users')
  }

  if (loading) {
    return (
      <div className="loading-spinner" style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh'
      }}>
        <div style={{
          width: '50px',
          height: '50px',
          border: '5px solid #f3f3f3',
          borderTop: '5px solid #e50202',
          borderRadius: '50%',
          animation: 'spin 1s linear infinite'
        }}></div>
        <style>{`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', overflow: "scroll", position: 'relative', overflowY: 'hidden', overflowX: 'hidden', }}>
      <div style={{ width: '60%' }}>
        <div>
          <div style={{ marginBottom: '0rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h4><strong>Add User</strong></h4>
            <CButton
              onClick={goBack}
              style={{
                width: 200,
                marginBottom: '2%',
                marginRight: 20,
                borderWidth: 0,
                fontSize: 13,
                borderColor: '#fff',
                borderWidth: 1,
                color: '#fff',
                background: 'transparent'
              }}
              color="primary"
              className="px-3"
            >
              <IoMdArrowRoundBack size={25} style={{ color: '#fff', marginRight: 10 }} />
              Back to Users
            </CButton>
          </div>

          <div style={{ width: '100%', backgroundColor: '#252B3B', padding: 15 }}>
            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>User Role</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="role"
                className="white-input"
                onChange={(e) => setRole(e.target.value)}
                style={{ width: '100%' }}
              >
                <option key="Administrator" value="Administrator">Administrator</option>
                <option key="Application Manager" value="Application Manager">Application Manager</option>
                <option key="Tester" value="Tester">Tester</option>
              </CFormSelect>
            </CInputGroup>

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>First Name</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormInput
                placeholder="First Name"
                autoComplete="given-name"
                className="white-input"
                onChange={(e) => setFirstName(e.target.value)}
                style={{ width: '100%' }}
              />
              {errors.firstName && <div style={{ color: 'red', marginTop: '5px' }}>{errors.firstName}</div>}
            </CInputGroup>

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Last Name</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormInput
                placeholder="Last Name"
                autoComplete="family-name"
                className="white-input"
                onChange={(e) => setLastName(e.target.value)}
                style={{ width: '100%' }}
              />
              {errors.lastName && <div style={{ color: 'red', marginTop: '5px' }}>{errors.lastName}</div>}
            </CInputGroup>

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Email</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormInput
                placeholder="Email"
                autoComplete="email"
                className="white-input"
                onChange={(e) => setEmail(e.target.value)}
                style={{ width: '100%' }}
              />
              {errors.email && <div style={{ color: 'red', marginTop: '5px' }}>{errors.email}</div>}
            </CInputGroup>

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Password</CFormLabel>
            <p style={{fontSize:12, fontStyle:'italic'}}>The password must be 12-128 characters long and contain at least one lowercase letter, one uppercase letter, one digit, and one special character.</p>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormInput
                placeholder="Password"
                autoComplete="new-password"
                type="password"
                className="white-input"
                onChange={(e) => setPassword(e.target.value)}
                style={{ width: '100%' }}
              />
              {errors.password && <div style={{ color: 'red', marginTop: '5px' }}>{errors.password}</div>}
            </CInputGroup>

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Phone Number</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
           

<PhoneInput
      placeholder="Enter phone number (without country code, if selected country)"
      value={phoneNumber}
      international={false}
      onChange={setPhoneNumber}/>

              {errors.phoneNumber && <div style={{ color: 'red', marginTop: '5px' }}>{errors.phoneNumber}</div>}
            </CInputGroup>        

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Business Unit</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="businessUnit"
                className="white-input"
                onChange={(e) => setBusinessUnit(e.target.value)}
                style={{ width: '100%' }}
              >
                {businessUnits.map(businessUnit => (
                  <option key={businessUnit._id} value={businessUnit._id}>
                    {businessUnit.name}
                  </option>
                ))}
              </CFormSelect>
            </CInputGroup>

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Location</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormInput
                placeholder="Location"
                autoComplete="address-level1"
                className="white-input"
                onChange={(e) => setLocation(e.target.value)}
                style={{ width: '100%' }}
              />
            </CInputGroup>

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Status</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="status"
                className="white-input"
                onChange={(e) => setTheStatus(e.target.value)}
                style={{ width: '100%' }}
              >
                {statuses.map(status => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </CFormSelect>
            </CInputGroup>


{errorText &&

<>
<br/>
        <span style={{color:'red', marginTop:20}}>{errorText}</span>

        </>
}

            <CButton
              style={{
                width: '100%',
                marginTop: '3%',
                marginBottom: '2%',
                borderWidth: 0,
                fontSize: 13,
                background: '#e50202'
              }}
              color="primary"
              className="px-3"
              onClick={addUser}
              disabled={loading}
            >
              {loading ?
                <CircularProgress color="primary" size={24} style={{ marginTop: 10, color: '#fff' }} />
                :
                'Save User'
              }
            </CButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddUser