import React from 'react';

const AtlassianBambooIntegration = () => {

  return (
    <div>
      <h3 className="darkText">Atlassian Bamboo Integration Instructions for SAST and SCA Scans</h3>
      <hr />

      <h4 className="darkText">Step 1: Run the Atlassian Bamboo Service and Agent</h4>
      <p>Ensure the Atlassian Bamboo service and agent are running.</p>

      <h4 className="darkText">Step 2: Create a Project</h4>
      <p>Create a new project in Atlassian Bamboo.</p>

      <h4 className="darkText">Step 3: Create a Plan</h4>
      <p>Set up a plan for the project to manage your builds and scans.</p>

      <h4 className="darkText">Step 4: Configure Your Code Repository</h4>
      <p>Go to the plan configuration and link your code repository.</p>

      <h4 className="darkText">Step 5: Create a New Stage</h4>
      <p>Go to the <strong>Stages</strong> section and create a new stage named <strong>Security Scans</strong>. Move this stage to the top and delete or disable the default stage.</p>

      <h4 className="darkText">Step 6: Add Jobs</h4>
      <p>Add two jobs under the <strong>Security Scans</strong> stage:</p>
      <ul>
        <li><strong>SAST Scan</strong></li>
        <li><strong>SCA Scan</strong></li>
      </ul>

      <h4 className="darkText">Step 7: Configure the SAST Scan Job</h4>
      <ol>
        <li>Go to the <strong>SAST Scan</strong> job.</li>
        <li>Add a new task and select the task type as <strong>Script</strong>.</li>
        <li>Follow the steps for script configuration below.</li>
        <li>Make sure to set the client secret variable as masked.</li>
      </ol>

      <h4 className="darkText">Script Configuration</h4>
      <ol>
        <li><strong>Task Description:</strong> Enter a recognizable description like <em>SAST Scan</em> or <em>SCA Scan</em>.</li>
        <li><strong>Interpreter:</strong> Choose <em>Shell</em> for Linux.</li>
        <li><strong>Script Location:</strong> Select <em>Inline</em>.</li>
        <li><strong>Script Body:</strong> Use the following script for SAST scans:</li>
      </ol>

      <pre>
        <code>
{`# Compress the project files excluding unnecessary ones
zip -r project.zip . -x "*.git*" "*/node_modules/*" "*/vendor/*" "*.zip"

# Call the API for SAST scan
RESPONSE=$(curl -X POST \\
  -H "Client-ID: \${bamboo.CLIENT_ID}" \\
  -H "Client-Secret: \${bamboo.CLIENT_SECRET}" \\
  -F "projectZipFile=@project.zip" \\
  -F "applicationId=\${bamboo.APPLICATION_ID}" \\
  -F "scanName=android-oss-kotlin SAST Scan - \${bamboo.buildNumber}" \\
  -F "language=kotlin" \\
https://appsecops-api.intruceptlabs.com/api/v1/integrations/sast-scans)
echo "$RESPONSE"
`}
        </code>
      </pre>

      <p>Follow the same steps for SCA scans but use the following script:</p>

      <pre>
        <code>
{`# Compress the project files excluding unnecessary ones
zip -r project.zip . -x "*.git*" "*/node_modules/*" "*/vendor/*" "*.zip"

# Call the API for SCA scan
RESPONSE=$(curl -X POST \\
  -H "Client-ID: \${bamboo.CLIENT_ID}" \\
  -H "Client-Secret: \${bamboo.CLIENT_SECRET}" \\
  -F "projectZipFile=@project.zip" \\
  -F "applicationId=\${bamboo.APPLICATION_ID}" \\
  -F "scanName=android-oss-kotlin SCA Scan - \${bamboo.buildNumber}" \\
  -F "language=kotlin" \\
https://appsecops-api.intruceptlabs.com/api/v1/integrations/sca-scans)
echo "$RESPONSE"
`}
        </code>
      </pre>

      <h4 className="darkText">Step 8: Run the Plan</h4>
      <p>After completing the configuration, 
        manually run the plan and check the logs in Atlassian Bamboo, for details of the found
        vulnerability. Detailed information on the scan will be available in the Intrucept 
        appsecops portal, under SAST Scans / SCA Scans.</p>
    </div>
  );
};

export default AtlassianBambooIntegration;
